// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-posts-index-js": () => import("./../../../src/pages/posts/index.js" /* webpackChunkName: "component---src-pages-posts-index-js" */),
  "component---src-pages-posts-mdx-slug-js": () => import("./../../../src/pages/posts/{mdx.slug}.js" /* webpackChunkName: "component---src-pages-posts-mdx-slug-js" */),
  "component---src-pages-projects-index-js": () => import("./../../../src/pages/projects/index.js" /* webpackChunkName: "component---src-pages-projects-index-js" */),
  "component---src-pages-projects-mdx-slug-js": () => import("./../../../src/pages/projects/{mdx.slug}.js" /* webpackChunkName: "component---src-pages-projects-mdx-slug-js" */),
  "component---src-pages-stories-index-js": () => import("./../../../src/pages/stories/index.js" /* webpackChunkName: "component---src-pages-stories-index-js" */),
  "component---src-pages-stories-mdx-slug-js": () => import("./../../../src/pages/stories/{mdx.slug}.js" /* webpackChunkName: "component---src-pages-stories-mdx-slug-js" */)
}

